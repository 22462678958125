.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgb(0 0 0 / 42%);
    z-index: 3;
  }
  
  .spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #ffffff transparent #ffffff transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
  }
  
  @keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
  p{
    word-break: break-word;
  }
  a {
    color: black;
    text-decoration: none;
  }
  a:hover{
    text-decoration: none;
  }
  .align-item-center{
    align-items: center;
  }
  .b-blue{
    background: #293b7b !important;
    color: white !important;
  }
  .c-blue{
    color: #293b7b !important;
  }
  .c-green{
    color: #03C988;
  }
  .c-red{
    color: #FF5E5E;
  }
  .c-origin{
    color: #F8AC22;
  }
  .c-gray{
    color: #AFB1BE;
  }
  .p-relative{
    position: relative;
  }
  .font-sm{
    font-size: 12px;
  }
  .font-md{
    font-size: 14px;
  }
  .font-lg{
    font-size: 16px;
  }
  .f-weight-500{
    font-weight: 500;
  }
  .f-weight-600{
    font-weight: 600;
  }
  .f-weight-700{
    font-weight: 700;
  }
  .f-weight-800{
    font-weight: 800;
  }
  .f-weight-900{
    font-weight: 900;
  }
  .word-break-all{
    word-break: break-all;
  }
  /* .scroll_up{
    display: block;
  }
  .scroll_down{
    display: none;
  } */
  .d_block_logo{
    display: block !important;
  }

  .loader_css{
    position: absolute;
    top: 35%;    
  }
  .m-s-auto{
    margin-left: auto !important;
  }
  @media (max-width: 768px) {
    .m-s-auto{
      margin-left: 0 !important;
    }
  }