.session_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Inconsolata', monospace;
    font-weight: 900;
}
img.img-fluid.gifimg {
    width: 200px;
}
.log_btn_session{
    background: red !important;
}