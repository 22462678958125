/* .home-bg {
    position: relative;
    width: 100%;
    background: linear-gradient(90deg, #060E4F, #56005B);
    color: white;
    overflow: hidden;
  
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-image: url('/public/images/bg.png');
      background-size: cover;
      background-position: center;
      filter: blur(50px); 
      z-index: -1;
    }
  } */

.back-color {
  background: rgb(0, 24, 103);
  background: linear-gradient(69deg, rgba(0, 24, 103, 1) 0%, rgba(24, 5, 48, 1) 100%);
}

.home-bg {
  /* background: rgb(1,19,80);
    background: linear-gradient(69deg, rgba(1,19,80,1) 0%, rgba(24,5,48,1) 100%); */
  position: relative;
}

.OI-btn {
  display: flex;
}

.title-head {
  width: 117%;
}

.head-text {
  color: gainsboro;
  letter-spacing: 1px;
}

.head-con {
  margin-top: 120px;
  color: white;
}

/* .clr-img img{
    height: 88vh;
  } */

/* .btn1 {
    background-color: #061875;
    background-color: rgba(6, 24, 117, 1), 0.2;;
    color: white;
    padding: 21px 35px;
    border: none;
    border-radius: 15px;
    backdrop-filter: blur(15px); 
    margin-right: 10px;
    outline: none;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 300, 0.5); 
} */

/* .btn2 {
    background-color: rgb(180, 180, 180);
    color: white;
    padding: 21px 35px;
    border: none;
    border-radius: 15px;
    backdrop-filter: blur(15px);
    margin-left: 10px;
    outline: none;
    position: relative;
    overflow: hidden;
  } */

.btn1 {
  border: 1px solid white;
  background-color: white;
  color: black;
  transition: 0.5s ease;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  padding: 16px;
  border: none;
  border-radius: 15px;
  line-height: 24px;
}

.btn2:hover,
.btn1 {
  background-color: white;
  color: black;
  box-shadow: 0 0 5px rgb(0, 24, 103);
  border: 1px solid white;
}

.btn2 {
  border: 1px solid white !important;
  background-color: transparent;
  color: white;
  margin-left: 20px;
  transition: 0.5s ease;
  cursor: pointer;
  padding: 16px 25px;
  /* padding: 21px 35%; */
  border: none;
  border-radius: 15px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.blueCircle {
  position: absolute;
  width: 6%;
  height: 13vh;
  border-radius: 50%;
  transition: top 1s, background-color 1s;
  left: 47%;
  top: -12%;
  z-index: 2;
}

.blueCircle.animate {
  top: 73%;
  z-index: 0;
}

/* .blueCircle {
  position: absolute;
  width: 6%;
  height: 13vh;
  border-radius: 50%;
  transition: top 1s, background-color 1s;
  left: 47%;
  top: -12%;
  z-index: 2;
  animation-name: moveCircle;
  animation-duration: 2s; 
  animation-fill-mode: forwards;
  opacity: 0;
}

.blueCircle.animate {
  animation-name: moveCircle;
  animation-duration: 2s; 
  animation-fill-mode: forwards;
}

@keyframes moveCircle {
  0% {
    top: -12%;
    opacity: 0;
  }
  100% {
    top: 157%;
    opacity: 1;
  }
} */


.blueCircle.blueBackground {
  background-color: blue !important;
}

.blueCircle.darkBackground {
  background-color: #141353 !important;
}

.ellipse {
  background-image: url(/public/images/Ellipsesmall.png);
  background-repeat: no-repeat;
  height: 130vh;
  background-position: center;
  position: relative;
}

/* .circle-bg {
  background-image: url(/public/images/bigEllipse.png);
  background-repeat: no-repeat;
  height: 130vh;
  background-position: center;
  position: relative;
  background-size: 50%;
  transition: background-size 2.5s ease-in-out;
} */



.circle-bg {
  position: relative;
  overflow-y: hidden;
  height: 130vh;
}

.circle-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/public/images/bigEllipse.png);
  background-size: cover;
  background-position: center;
  z-index: -1;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.content-wrapper {
  position: relative;
  z-index: 1;
  overflow-y: auto;
  max-height: calc(120vh - 0px);
}

.content-wrapper::-webkit-scrollbar {
  width: 0;
}

.content-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.content-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
}

.swiper-container {
  /* Styles for your Swiper container */
}

.scrollable-content {
  margin-top: 15%;
  margin-bottom: 5%;
}

.swiper-btn {
  display: flex;
  position: absolute;
  top: 20%;
  left: 35%;
  z-index: 2;
}



.circle-bg.expand {
  background-size: 100%;
  /* Expand to full size */
}

.circle-head h1 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 49px;
  color: #bbcbed;
  z-index: 3;
}

.swiper {
  position: relative;
  top: 28%;
}

.boxes {
  position: relative;
  top: 25%;
}

.aqua-img {
  margin-top: 60px;
}

.rec-box {
  background-color: white;
  border-radius: 30px;
  width: 90%;
  margin-top: 20%;
}

.rec-bx-head {
  background-image: linear-gradient(90deg, #060E4F, #56005B);
  color: transparent;
  background-clip: text;
  font-weight: bolder;
}

.blue-clr {
  color: #0A0D4C;

}

.blue-clr2 {
  color: #ffff;
}

.yellow-clr {
  color: #FFAB33;
}

.rec-box h4 {
  margin-top: 35px;
}

.btn-sub {
  background-color: #04097C;
  color: white;
  padding: 24px 57px;
  border-radius: 10px;
  border: none;
  font-size: 23px;
  margin: 40px 0px 40px 0px !important;
}

.btn-sub:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.holder h1 {
  font-size: 38px;
  color: #bbcbed;
}

.rec-2-box {
  background: url('/public/images/rec-2.png');
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-top: 3%;
}

.rec-2-sh {
  /* color: #29097C; */
  color: #9E9E9E;
}

.yellow-clr-button {
  background-color: #EDA948;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px 25px;
  cursor: pointer;
  margin-bottom: 15px;
}

.yellow-clr-button:hover {
  background-color: #ec951b;
}

.ver-ani {
  margin-right: 90px;
}

article {
  overflow: hidden;
  position: relative;
  height: 27px;
}

article span {
  font-size: 18px;
}

span.text-ani {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  animation: move 6s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  text-align: center;
}

@keyframes move {
  0% {
    top: 0px;
  }

  50% {
    top: -25px;
  }

  100% {
    top: -50px;
  }
}

span.text-ani1 {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  top: 0;
  left: 0;
  animation: move1 6s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

@keyframes move1 {
  0% {
    top: -50px;
  }

  50% {
    top: -25px;
  }

  100% {
    top: 0px;
  }
}

.logos {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.logos:hover {
  filter: grayscale(0%);
}

.logos1 {
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.logos1:hover {
  filter: grayscale(0%);
}

.logo-image1 {
  display: flex;
  justify-content: center;
}

.logo-image2 {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.logo-mar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 53px;
}

.rec-2-img {
  margin: 0px 40px;
}

.last-sec {
  margin-left: 50%;
}



.swiper-button-prev,
.swiper-button-next {
  color: #646464 !important;
  padding: 10px; 
  border-radius: 50%;
}

.rec-box-popup {
  background-color: white;
  border-radius: 30px;
  width: 90%;
  margin-top: 5%;
}




/* @media only screen and (max-width:1440px) {
  .blueCircle {
    position: absolute;
    width: 6%;
    height: 15vh;
  }

  .blueCircle.animate {
    top: 161%;
    z-index: 0;
  }
} */

@media only screen and (max-width:1024px) {
  .banner-img {
    margin-top: 60px;
  }

  .blueCircle {
    position: absolute;
    width: 8%;
    height: 15vh;
    left: 46%;
  }

  .blueCircle.animate {
    top: 74%;
    z-index: 0;
  }

}

@media only screen and (max-width:991px) {
  .blueCircle.animate {
    top: 81.5%;
    z-index: 0;
  }
}

@media only screen and (max-width:768px) {

  .blueCircle.animate {
    top: 81%;
    z-index: 0;
  }

  .blueCircle {
    position: absolute;
    width: 8%;
    height: 12vh;
    left: 46%;
  }

  .circle-head h1 {
    font-size: 40px;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 30%;
  }

  .rec-bx-head {
    background-image: linear-gradient(90deg, #060E4F, #56005B);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: bolder;
    font-size: 30px;
  }

  .ellipse {
    background-repeat: no-repeat;
    height: 138vh;
    background-position: center;
    position: relative;
  }

  .circle-bg {
    /* background-image: url(http://localhost:3001/static/media/bg-circle.01bd6c5….png); */
    background-repeat: no-repeat;
    height: 138vh;
    background-position: center;
    position: relative;
  }

  .btn-sub {
    background-color: #04097C;
    color: white;
    padding: 20px 45px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    margin-top: 60px !important;
  }
}

@media only screen and (max-width:631px) {
  .blueCircle {
    position: absolute;
    width: 8%;
    height: 10vh;
    left: 46%;
  }

  .blueCircle.animate {
    top: 79.8%;
    z-index: 0;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 40%;
  }
}

@media only screen and (max-width:600px) {
  .blueCircle {
    position: absolute;
    width: 8%;
    height: 10vh;
    left: 46%;
  }

  .blueCircle.animate {
    top: 80%;
    z-index: 0;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 40%;
  }
}

@media only screen and (max-width:575px) {
  .ver-ani {
    margin-right: 65px;
  }

  .blueCircle.animate {
    top: 80.4%;
    z-index: 0;
  }

  .blueCircle {
    position: absolute;
    width: 9%;
    height: 10vh;
    left: 45%;
  }

  .circle-bg {
    /* background-image: url(http://localhost:3001/static/media/bg-circle.01bd6c5….png); */
    background-repeat: no-repeat;
    height: 136vh;
    background-position: center;
    position: relative;
  }

  .ellipse {
    background-repeat: no-repeat;
    height: 137vh;
    background-position: center;
    position: relative;
    width: 100%;
    max-width: 81%;
    margin: 0px auto;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 54%;
  }

  .rec-2-img {
    margin: 0px 25px;
  }

  .logos1 {
    padding: 15px 0px;
  }

  .logo-image2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rec-box h4 {
    margin-top: 45px;
  }

  .btn-sub {
    background-color: #04097C;
    color: white;
    padding: 16px 35px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    margin-top: 45px !important;
  }
}

@media only screen and (max-width:425px) {

  .ellipse {
    background-repeat: no-repeat;
    height: 127vh;
    background-position: center;
    position: relative;
    width: 100%;
    max-width: 81%;
    margin: 0px auto;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 70%;
  }

  .blueCircle {
    position: absolute;
    width: 9%;
    height: 7vh;
    left: 45%;
  }

  .blueCircle.animate {
    top: 81%;
    z-index: 0;
  }

  .OI-btn {
    display: block;
  }

  .btn1 {
    margin-left: 80px;
  }

  .btn2 {
    margin-left: 80px;
    margin-top: 25px;
  }

  .rec-2-img {
    margin: 0px 25px 0px 0px;
  }

  .text-ani {
    font-size: 16px;
  }

  .text-ani1 {
    font-size: 16px;
  }

  .ver-ani {
    margin-right: 40px;
  }

  .rec-bx-head {
    background-image: linear-gradient(90deg, #060E4F, #56005B);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-weight: bolder;
    font-size: 18px;
  }


  .blue-clr {
    color: #0A0D4C;

  }

  .blue-clr2 {
    color: #ffff;
    font-size: 18px;
  }

  .yellow-clr {
    color: #FFAB33;
    font-size: 18px;
  }

  .btn-sub {
    background-color: #04097C;
    color: white;
    padding: 12px 27px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    margin-top: 45px !important;
  }

  .circle-bg {
    /* background-image: url(http://localhost:3001/static/media/bg-circle.01bd6c5….png); */
    background-repeat: no-repeat;
    height: 127vh;
    background-position: center;
    position: relative;
  }

  .head-text {
    color: gainsboro;
    letter-spacing: 1px;
    font-size: 12px;
  }
}

@media only screen and (max-width:375px) {
  .blueCircle.animate {
    top: 51.3%;
    z-index: 0;
  }

  .btn1 {
    margin-left: 55px;
  }

  .btn2 {
    margin-left: 55px;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 65%;
  }

  .ellipse {
    background-repeat: no-repeat;
    height: 137vh;
    background-position: center;
    position: relative;
    /* width: 100%; */
    max-width: 93%;
    margin: 0px auto;
  }

  .circle-bg {
    /* background-image: url(http://localhost:3001/static/media/bg-circle.01bd6c5….png); */
    background-repeat: no-repeat;
    height: 137vh;
    background-position: center;
    position: relative;
  }
}

@media only screen and (max-width:320px) {
  .blueCircle.animate {
    top: 43.3%;
    z-index: 0;
  }

  .blueCircle {
    position: absolute;
    width: 9%;
    height: 6vh;
    left: 45%;
  }

  .ellipse {
    background-repeat: no-repeat;
    height: 137vh;
    background-position: center;
    position: relative;
    /* width: 100%; */
    max-width: 100%;
    margin: 0px auto;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 77%;
  }

  .logos {
    padding: 15px 0px;
  }

  .logo-image1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .btn1 {
    margin-left: 30px;
  }

  .btn2 {
    margin-left: 30px;
  }

  .rec-2-img {
    margin: 0px 0px 0px 0px;
  }

  input.form-input {
    display: flex;
    width: 230px;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #DDE8ED;
    background: #F7F8FA;
  }

  .head-text {
    color: gainsboro;
    letter-spacing: 1px;
    font-size: 12px;
  }
}



.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.circle {
  background-color: blue;
  border-radius: 50%;
  transition: width 0.5s, height 0.5s;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.upsale-button span:hover {
  background-color: #2b46da;
  color: #ffff;
  box-shadow: 0 0 5px rgb(0, 24, 103);
  /* border: 1px solid white; */
}

.upsale-button {
  position: relative;
  z-index: 0;
  height: 70px;
  overflow: hidden;
  border: none !important;
  padding: 0 !important;
  background: #1f2d69 !important;
  color: #656565;
  background: white;
  border-radius: 8px;
  width: 250px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 0 4px 50px 0 rgb(0 0 0 / 7%);
  transition: .2s all linear;
  text-decoration: initial;
}

.upsale-button span {
  position: relative;
  z-index: 1;
  height: 95%;
  width: 98%;
  top: 0px;
  left: 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  color: white;
  background: #1f2d69;
  font-size: 20px;
}

.upsale-button:after {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}


@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.lastcircle-bg {
  background-image: url('/public/images/bg-circle.png');
  background-repeat: no-repeat;
  height: auto;
  background-position: center;
  position: relative;
  background-size: cover;
  /* animation: hideBackground 5s forwards */
}

.lastcircle-bg.hide-background {
  animation: hideBackground 5s forwards;
  /* Animation to hide background after 5 seconds */
}

@keyframes hideBackground {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* .section-animation {
  opacity: 1;
  transition: opacity 4.5s ease-out; 
}

.section-animation.show {
  opacity: 1;
} */
.backgroun-img {
  position: absolute;
  z-index: 1;
  height: 70%;
  left: 35%;
  width: -webkit-fill-available;
  animation: hideBackground 5s forwards
}


@media only screen and (max-width: 360px) and (max-height: 800px) {
  .blueCircle {
    position: absolute;
    width: 8%;
    height: 4vh !important;
    left: 46%;
  }

  .blueCircle.animate {
    top: 74.5% !important;
    z-index: 0;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 87%;
  }
}

@media only screen and (max-width: 393px) and (max-height: 851px) {
  .blueCircle {
    position: absolute;
    width: 8%;
    height: 4vh !important;
    left: 46%;
  }

  .blueCircle.animate {
    top: 74.7% !important;
    z-index: 0;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 87%;
  }
}

@media only screen and (max-width: 412px) and (max-height: 915px) {
  .blueCircle {
    position: absolute;
    width: 8%;
    height: 4vh !important;
    left: 46%;
  }

  .blueCircle.animate {
    top: 74% !important;
    z-index: 0;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 87%;
  }
}

@media only screen and (max-width: 414px) and (max-height: 896px) {
  .blueCircle {
    position: absolute;
    width: 8%;
    height: 4vh !important;
    left: 46%;
  }

  .blueCircle.animate {
    top: 74.3% !important;
    z-index: 0;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 87%;
  }
}

@media only screen and (max-width: 428px) and (max-height: 926px) {
  .blueCircle {
    position: absolute;
    width: 8%;
    height: 4vh !important;
    left: 46%;
  }

  .blueCircle.animate {
    top: 72.5% !important;
    z-index: 0;
  }

  .rec-box {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin-top: 87%;
  }
}