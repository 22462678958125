body {
    background-color: rgb(248, 248, 248);
}

.css-1ndpvdd-MuiTableCell-root {
    font-weight: 600 !important;
}

.inventor-background {
    background-color: white;
    border-radius: 8px;
}

.box {
    background-color: rgb(247, 247, 247);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.box h4 {
    color: #2B377A;
}

.table {
    background-color: rgb(247, 247, 247);
    border-radius: 8px;
}

.head-blue {
    color: #2B377A;
}

.edit-icon {
    color: green;
    cursor: pointer;
}

.delete-icon {
    color: red;
    cursor: pointer;
}